<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Manage testimonials</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Testimonials
                </div>
              </div>
              <div class="breadcrumb-right">
                <v-btn
                  @click="createTestimonial()"
                  v-if="checkIsAccessible('testimonial', 'create')"
                  class="mt-4 btn btn-primary mr-1"
                  dark
                >
                  <i class="fa fa-plus"></i>
                  Add testimonial
                </v-btn>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start"
            >
              <div class="row">
                <v-col cols="12" md="6">
                  <v-text-field
                    label="Title"
                    type="text"
                    v-on:keyup.enter="getTestinomial()"
                    dense
                    outlined
                    v-model="search.title"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="3">
                  <v-select
                    label="Status"
                    outlined
                    dense
                    :items="active_statuses"
                    item-text="title"
                    item-value="value"
                    clearable
                    v-on:keyup.enter="getTestinomial()"
                    v-model="search.active"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="3" class="text-right">
                  <v-btn
                    @click="getTestinomial()"
                    class="btn btn-primary btn-search w-35"
                    :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="">
              <v-skeleton-loader type="table-thead" v-if="loading"> </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                  <tr class="px-3">
                    <th class="px-3 wrap-column">Image</th>
                    <th class="px-3 wrap-column">Title</th>
                    <th class="px-3 wrap-column">Rating</th>
                    <th class="pr-3 text-center">Action</th>
                  </tr>
                </thead>
                <draggable
                  v-show="testimonial.length > 0"
                  @change="sort"
                  v-model="testimonial"
                  class="text-left"
                  tag="tbody"
                >
                  <tr v-for="(testinomial, index) of testimonial" :key="index">
                    <td class="px-3 wrap-column">
                      <i class="cursor-drag fas fa-sort handle pr-2"></i>
                      <img
                        v-if="testinomial.image_path.thumb"
                        :src="testinomial.image_path['thumb']"
                        alt
                        style="height: 75px"
                        class="img-thumbnail"
                      />
                    </td>
                    <td class="px-3 wrap-column">
                      <a href="#" @click="editTestinomial(testinomial.id)" class="mr-2">
                        {{ testinomial.title }}</a
                      >&nbsp;&nbsp;
                      <i
                        class="fas fa-circle"
                        :class="testinomial.is_active ? 'dot-active' : 'dot-inactive'"
                      ></i>
                    </td>

                    <td class="px-3 wrap-column">
                      <v-rating
                        v-model="testinomial.rating"
                        color="yellow darken-3"
                        background-color="grey darken-1"
                        empty-icon="$ratingFull"
                        half-increments
                        readonly
                        hover
                        small
                      ></v-rating>
                    </td>
                    <td class="px-2 text-center">
                      <b-dropdown
                        variant="link"
                        toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                        no-caret
                        right
                        no-flip
                      >
                        <template v-slot:button-content>
                          <slot>
                            <span>
                              <i class="flaticon-more-1"></i> </span
                          ></slot>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover">
                          <b-dropdown-text tag="div" class="navi-item">
                            <a
                              href="#"
                              class="navi-link"
                              @click="editTestinomial(testinomial.id)"
                            >
                              <span class="navi-icon">
                                <i class="flaticon-edit"></i>
                              </span>
                              <span class="navi-text"> Edit</span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item">
                            <a
                              href="#"
                              class="navi-link"
                              @click="deleteTestimonial(testinomial.id)"
                            >
                              <span class="navi-icon">
                                <i class="fas fa-trash"></i>
                              </span>
                              <span class="navi-text"> Delete</span>
                            </a>
                          </b-dropdown-text>
                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>
                    </td>
                  </tr>
                </draggable>
                <tr v-if="testimonial.length == 0">
                  <td colspan="4" class="text-center">Data not available</td>
                </tr>
              </table>
              <b-pagination
                v-if="testimonial.length > 0"
                @input="getTestinomial"
                class="pull-right mt-7"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import TestimonialService from "@/core/services/testimonial/TestimonialService";
import draggable from "vuedraggable";

const testimonialService = new TestimonialService();
export default {
  name: "Testimonial",
  display: "Table",
  order: 8,
  components: { draggable },
  data() {
    return {
      testimonial: [],
      active_statuses: [
        { title: "Active", value: "active" },
        { title: "Inactive", value: "in_active" },
      ],
      loading: false,
      page: null,
      total: null,
      perPage: null,
      search: {
        active: "active",
      },
    };
  },
  mounted() {
    this.getTestinomial();
  },
  methods: {
    getTestinomial() {
      this.loading = true;
      testimonialService.paginate(this.search, this.page).then((response) => {
        this.testimonial = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
        this.loading = false;
      });
    },
    editTestinomial(testinomialId) {
      this.$router.push({ name: "testimonial-update", params: { id: testinomialId } });
    },
    sort() {
      testimonialService
        .sort(this.testimonial)
        .then((res) => {
          this.$snotify.success("Sorted");
          this.getTestinomial();
        })
        .catch((err) => {
          this.$snotify.success("Sorted Error");
        });
    },
    createTestimonial() {
      this.$router.push({
        name: "testimonial-new",
      });
    },
    deleteTestimonial(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            testimonialService
              .delete(id)
              .then((response) => {
                this.$snotify.success("Information deleted");

                this.getTestinomial();
              })
              .catch((error) => {
                //console.log(error);
              });
          }
        },
      });
    },
  },
};
</script>
